import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

export const decrypt = (eventCipher: string, key: string) => {
    const bytes = AES.decrypt(eventCipher.toString(), key);

    try {
        const plaintext = bytes.toString(encUtf8);
        const event = JSON.parse(plaintext);
        return event;
    } catch (error) {
        // SentryService.captureMessage('Could not decrypt famlytics event.', {
        //     extra: {
        //         error,
        //         eventCipher,
        //     },
        // });
        return undefined;
    }
};
