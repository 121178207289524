import i18next from 'i18next';

import {hasValue} from '@famly/stat_ts-utils_has-value';
import {isNonEmpty} from 'web-app/util/non-empty-array';

const internalGetRecipientString = (recipients: Array<string> | null | undefined, cutoff?: number, isOr?: boolean) => {
    if (!hasValue(recipients)) {
        return '';
    }

    if (!isNonEmpty(recipients)) {
        return '';
    } else if (recipients.length === 1) {
        return recipients[0];
    } else if (cutoff && cutoff < recipients.length) {
        const recipientsToShow = recipients.slice(0, cutoff);
        const lengthOfRest = recipients.length - cutoff;
        return `${recipientsToShow.join(', ')} ${i18next.t(isOr ? 'orNMore' : 'andNMore', {count: lengthOfRest})}`;
    } else {
        const last = recipients.at(-1);
        const shortenedRecipients = recipients.slice(0, -1);
        return `${shortenedRecipients.join(', ')} ${i18next.t(isOr ? 'or' : 'and')} ${last}`;
    }
};

export const getRecipientString = (recipients: Array<string> | null | undefined, cutoff?: number) => {
    return internalGetRecipientString(recipients, cutoff, false);
};

export const getRecipientStringWithOr = (recipients: Array<string> | null | undefined, cutoff?: number) => {
    return internalGetRecipientString(recipients, cutoff, true);
};

export const getRecipientStringWithMarkdown = (recipients: Array<string>, cutoff?: number) => {
    if (!isNonEmpty(recipients)) {
        return '';
    } else if (recipients.length === 1) {
        return `**${recipients.at(0)}**`;
    } else if (cutoff && cutoff < recipients.length) {
        const recipientsToShow = recipients.slice(0, cutoff);
        const lengthOfRest = recipients.length - cutoff;
        return `**${recipientsToShow.join(', ')}** ${i18next.t('andNMore', {count: lengthOfRest})}`;
    } else {
        const last = recipients.at(-1);
        const shortenedRecipients = recipients.slice(0, -1);
        return `**${shortenedRecipients.join(', ')}** ${i18next.t('and')} ${last}`;
    }
};

export const isEmptyString = s => s === undefined || s === '' || (typeof s === 'string' && s.trim() === '');

// Converts numbers to letters, recursing over the alphabet for each multiplicity of 26
// i.e. 89 becomes CK
const baseChar = 'A'.charCodeAt(0);
export const numbersToLetters = (num: number, recursion = ''): string => {
    if (num > 0) {
        return numbersToLetters(Math.floor(num / 26), String.fromCharCode(baseChar + ((num - 1) % 26)) + recursion);
    }
    return recursion;
};

export const stringMatch = (a?: string, b?: string, ignoreCase?: boolean) =>
    (a === undefined && b === undefined) ||
    a === b ||
    Boolean(ignoreCase && typeof a === 'string' && typeof b === 'string' && a.toLowerCase() === b.toLowerCase());

export const capitalizeFirstLetter = (s: string, lowerCaseRest?: boolean) => {
    if (s.length === 0) {
        return s;
    }
    if (lowerCaseRest) {
        return `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`;
    }
    return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};

/**
 * Converts a given string to camel case on every `.` found within the string
 *
 * @example
 *
 * const toTransform = "hello.world";
 * const transformed = convertStringDotsToCamelCase(toTransform);
 * console.log(transformed) // prints "helloWorld"
 *
 * @param toTransform string to transform to camelCase
 * @returns the transformed string
 */
export function convertStringDotsToCamelCase(toTransform: string): string {
    return toTransform.split('.').reduce((a, b) => a + capitalizeFirstLetter(b));
}

/**
 * Converts the given input string from any string case to kebab-case.
 *
 * this is useful when creating e2e ids
 *
 * @see tests
 * @param toTransform string to transform to kebab-case
 * @returns the converted string
 */
export function convertToKebabCase(toTransform: string): string {
    return toTransform
        .trim()
        .replace(/([a-z0-9])([A-Z])|[\W_]+/g, (_, p1, p2) => (p1 ? `${p1}-${p2}` : '-'))
        .toLowerCase();
}

const Organization = 'Famly.Daycare:Organization';
const Institution = 'Famly.Daycare:Institution';
const Department = 'Famly.Daycare:Department';
const Group = 'Famly.Daycare:Group';
const Tag = 'Famly.Daycare:Tag';
const Child = 'Famly.Daycare:Child';

/**
 * Convertion between regular IDs and legacy backend IDs
 * Follows format "Foo|Bar" where Foo is type and Bar is ID
 *
 * .. Add more types if needed. At the time of implementation I just added what I needed.
 */
export type RecipientType =
    | typeof Organization
    | typeof Institution
    | typeof Department
    | typeof Group
    | typeof Tag
    | typeof Child;

export const RecipientTypes = {
    Organization,
    Institution,
    Department,
    Group,
    Tag,
    Child,
} as const;

export const fromChildIdToRecipient = (id: string) => fromIdToRecipient(id, Child);

const fromIdToRecipient = (id: string, type: RecipientType) => `${type}|${id}`;

export const fromRecipientToId = (recipient: string) => {
    const [first, last] = recipient.split('|');
    return last || first;
};

export const fromRecipientToType = (recipient: string) => {
    const [first] = recipient.split('|');
    return first;
};
