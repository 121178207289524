import {hasValue, exhaustiveCheck} from 'web-app/util/typescript';

import ENV from './environment';
import {WhitelabelUrl, WhitelabelMobileUrl, WhitelabelAppId, Whitelabel} from './white-labeling-constants';

export const OVERRIDE_WHITE_LABEL_KEY = 'famly.OVERRIDE_WHITE_LABEL_KEY';

const makeWhitelabelCheckerFunction = (whitelabelAppId: WhitelabelAppId) => () =>
    window.location.host.endsWith(WhitelabelUrl[whitelabelAppId]) || ENV.WHITELABEL_APP_ID === whitelabelAppId;

const isEspira = makeWhitelabelCheckerFunction(WhitelabelAppId.espira);
const isKido = makeWhitelabelCheckerFunction(WhitelabelAppId.kido);
const isBrightHorizons = makeWhitelabelCheckerFunction(WhitelabelAppId.brighthorizons);
const isNFamilyClub = makeWhitelabelCheckerFunction(WhitelabelAppId.nfamilyclub);
const isKindred = makeWhitelabelCheckerFunction(WhitelabelAppId.kindred);
const isMonkeyPuzzle = makeWhitelabelCheckerFunction(WhitelabelAppId.monkeypuzzle);
const isGrandirUK = makeWhitelabelCheckerFunction(WhitelabelAppId.grandiruk);
const isNuffieldHealth = makeWhitelabelCheckerFunction(WhitelabelAppId.nuffieldhealth);
const isKinderzimmerUk = makeWhitelabelCheckerFunction(WhitelabelAppId.kinderzimmeruk);
const isCpcTreehouse = makeWhitelabelCheckerFunction(WhitelabelAppId.cpctreehouse);
const isCpcOrchards = makeWhitelabelCheckerFunction(WhitelabelAppId.cpcorchards);
const isBusybees = makeWhitelabelCheckerFunction(WhitelabelAppId.busybees);
const isMontessori = makeWhitelabelCheckerFunction(WhitelabelAppId.bbmontessori);
const isOdyssey = makeWhitelabelCheckerFunction(WhitelabelAppId.bbodyssey);

let _determinedWhitelabel: Whitelabel | null | undefined;

const determineWhitelabel = () => {
    const overriddenWhitelabel = window.sessionStorage?.getItem(OVERRIDE_WHITE_LABEL_KEY);

    if (hasValue(overriddenWhitelabel) && isValidWhitelabelKey(overriddenWhitelabel)) {
        return Whitelabel[overriddenWhitelabel] as unknown as Whitelabel;
    }

    if (isEspira()) {
        return Whitelabel.espira;
    } else if (isKido()) {
        return Whitelabel.kido;
    } else if (isBrightHorizons()) {
        return Whitelabel.brighthorizons;
    } else if (isNFamilyClub()) {
        return Whitelabel.nfamilyclub;
    } else if (isKindred()) {
        return Whitelabel.kindred;
    } else if (isMonkeyPuzzle()) {
        return Whitelabel.monkeypuzzle;
    } else if (isGrandirUK()) {
        return Whitelabel.grandiruk;
    } else if (isNuffieldHealth()) {
        return Whitelabel.nuffieldhealth;
    } else if (isKinderzimmerUk()) {
        return Whitelabel.kinderzimmeruk;
    } else if (isCpcTreehouse()) {
        return Whitelabel.cpctreehouse;
    } else if (isCpcOrchards()) {
        return Whitelabel.cpcorchards;
    } else if (isBusybees()) {
        return Whitelabel.busybees;
    } else if (isMontessori()) {
        return Whitelabel.bbmontessori;
    } else if (isOdyssey()) {
        return Whitelabel.bbodyssey;
    } else {
        return null;
    }
};

const isValidWhitelabelKey = (whitelabel: string) => {
    return hasValue(Whitelabel[whitelabel]);
};

export const getWhitelabel = () => {
    if (_determinedWhitelabel !== undefined) {
        return _determinedWhitelabel;
    } else {
        const whitelabel = determineWhitelabel();
        _determinedWhitelabel = whitelabel;
        return _determinedWhitelabel;
    }
};

export const overrideWhitelabel = (whitelabelKey: string) => {
    if (!isValidWhitelabelKey(whitelabelKey)) {
        return;
    }

    const whitelabelValue = Whitelabel[whitelabelKey];

    _determinedWhitelabel = whitelabelValue;
    window.sessionStorage.setItem(OVERRIDE_WHITE_LABEL_KEY, whitelabelKey);
};

/**
 * These ids maps to how the backend configures push notifications.
 * We send this ID along with the push notification token when we register the app installation
 * for push notifications in the backend.
 *
 * When inserting new whitelabel apps, generate a random UUID, and communicate that to the backend
 * that's responsible for configuring the push notifications.
 */
export const getWhitelabelPushAppId = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case Whitelabel.espira:
            return 'eca36102-6851-4f10-959c-cfbb2663f82f';
        case Whitelabel.kido:
            return '2e45692d-b3e3-4eb2-8ca2-a2f6c4c6f644';
        case Whitelabel.brighthorizons:
            return 'c70d4421-c568-4b0d-a6a4-8545d318d1c0';
        case Whitelabel.nfamilyclub:
            return '9eb1753f-245a-448d-a351-09c8a11b3f53';
        case Whitelabel.kindred:
            return 'b20f9135-28c2-47c7-a811-03956d0105ec';
        case Whitelabel.monkeypuzzle:
            return '49af27e6-b657-4449-8799-0e7a1ee52474';
        case Whitelabel.grandiruk:
            return '6cf90c67-1ca8-469b-9423-5d965c39e78e';
        case Whitelabel.nuffieldhealth:
            return 'dd3105da-7818-4c83-aa07-77369081b3c1';
        case Whitelabel.kinderzimmeruk:
            return '83a27c8b-89f4-4140-ab89-3376ec11472c';
        case Whitelabel.cpctreehouse:
            return '5a8d5aa2-1be4-440b-a18a-6ac1541984c3';
        case Whitelabel.cpcorchards:
            return '9657d9f4-0620-496c-9a83-949d7b342672';
        case Whitelabel.busybees:
            return '0146e4a4-5d6a-4949-8e53-b06be4dc33f1';
        case Whitelabel.bbmontessori:
            return '1633421d-94a4-474e-b3a1-e0e1ffcc232b';
        case Whitelabel.bbodyssey:
            return '43352584-db51-4066-b177-7a35f361b44f';
        case null:
            return FamlyPushAppId;
        default:
            exhaustiveCheck(whitelabel);
            return FamlyPushAppId;
    }
};

const FamlyPushAppId = 'b0b82a57-3922-46ea-a686-d04c28b0c9f2';

/**
 * The value returned from this is used wherever we mention the "product name".
 */
export const getWhitelabelName = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case Whitelabel.espira:
            return 'Spireportalen';
        case Whitelabel.kido:
            return 'Kido';
        case Whitelabel.brighthorizons:
            return 'Bright Horizons';
        case Whitelabel.nfamilyclub:
            return 'N Family Club';
        case Whitelabel.kindred:
            return 'Kindred';
        case Whitelabel.monkeypuzzle:
            return 'Monkey Puzzle';
        case Whitelabel.grandiruk:
            return 'Grandir UK';
        case Whitelabel.nuffieldhealth:
            return 'Nuffield Health Nursery';
        case Whitelabel.kinderzimmeruk:
            return 'Kinderzimmer';
        case Whitelabel.cpctreehouse:
            return 'Treehouse Children’s Academy';
        case Whitelabel.cpcorchards:
            return 'Orchard Academy';
        case Whitelabel.busybees:
            return 'Busy Bees';
        case Whitelabel.bbmontessori:
            return 'Montessori by Busy Bees';
        case Whitelabel.bbodyssey:
            return 'Odyssey Global Preschool';
        case null:
            return 'Famly';
        default:
            exhaustiveCheck(whitelabel);
            return 'Famly';
    }
};

export const getWhitelabelUrl = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case Whitelabel.espira:
            return WhitelabelUrl.espira;
        case Whitelabel.kido:
            return WhitelabelUrl.kido;
        case Whitelabel.brighthorizons:
            return WhitelabelUrl.brighthorizons;
        case Whitelabel.nfamilyclub:
            return WhitelabelUrl.nfamilyclub;
        case Whitelabel.kindred:
            return WhitelabelUrl.kindred;
        case Whitelabel.monkeypuzzle:
            return WhitelabelUrl.monkeypuzzle;
        case Whitelabel.grandiruk:
            return WhitelabelUrl.grandiruk;
        case Whitelabel.nuffieldhealth:
            return WhitelabelUrl.nuffieldhealth;
        case Whitelabel.kinderzimmeruk:
            return WhitelabelUrl.kinderzimmeruk;
        case Whitelabel.cpctreehouse:
            return WhitelabelUrl.cpctreehouse;
        case Whitelabel.cpcorchards:
            return WhitelabelUrl.cpcorchards;
        case Whitelabel.busybees:
            return WhitelabelUrl.busybees;
        case Whitelabel.bbmontessori:
            return WhitelabelUrl.bbmontessori;
        case Whitelabel.bbodyssey:
            return WhitelabelUrl.bbodyssey;
        case null:
            return null;
        default:
            exhaustiveCheck(whitelabel);
            return null;
    }
};

export const getWhitelabelMobileAppUrl = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case Whitelabel.espira:
            return WhitelabelMobileUrl.espira;
        case Whitelabel.kido:
            return WhitelabelMobileUrl.kido;
        case Whitelabel.brighthorizons:
            return WhitelabelMobileUrl.brighthorizons;
        case Whitelabel.nfamilyclub:
            return WhitelabelMobileUrl.nfamilyclub;
        case Whitelabel.kindred:
            return WhitelabelMobileUrl.kindred;
        case Whitelabel.monkeypuzzle:
            return WhitelabelMobileUrl.monkeypuzzle;
        case Whitelabel.grandiruk:
            return WhitelabelMobileUrl.grandiruk;
        case Whitelabel.nuffieldhealth:
            return WhitelabelMobileUrl.nuffieldhealth;
        case Whitelabel.kinderzimmeruk:
            return WhitelabelMobileUrl.kinderzimmeruk;
        case Whitelabel.cpctreehouse:
            return WhitelabelMobileUrl.cpctreehouse;
        case Whitelabel.cpcorchards:
            return WhitelabelMobileUrl.cpcorchards;
        case Whitelabel.busybees:
            return WhitelabelMobileUrl.busybees;
        case Whitelabel.bbmontessori:
            return WhitelabelMobileUrl.bbmontessori;
        case Whitelabel.bbodyssey:
            return WhitelabelMobileUrl.bbodyssey;
        case null:
            return 'com.famly.famlyapp';
        default:
            exhaustiveCheck(whitelabel);
            return 'com.famly.famlyapp';
    }
};

export const isWhitelabelRingfenced = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case Whitelabel.brighthorizons:
        case Whitelabel.busybees:
        case Whitelabel.bbmontessori:
        case Whitelabel.bbodyssey:
            return true;
        case Whitelabel.espira:
        case Whitelabel.kido:
        case Whitelabel.nfamilyclub:
        case Whitelabel.kindred:
        case Whitelabel.monkeypuzzle:
        case Whitelabel.grandiruk:
        case Whitelabel.nuffieldhealth:
        case Whitelabel.kinderzimmeruk:
        case Whitelabel.cpctreehouse:
        case Whitelabel.cpcorchards:
        case null:
            return false;
        default:
            exhaustiveCheck(whitelabel);
            return false;
    }
};
